<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-card>
                <b-form>
                    <b-row>
                        <!--Date-->
                        <b-col xl="3" md="3" sm="12">
                            <b-form-group>
                                <validation-provider #default="{ errors }" name="date" rules="required">
                                    <h5 class="font-weight-bold">{{ $t('Date') }}</h5>
                                    <flat-pickr v-model="date" class="form-control" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!--Start Time-->
                        <b-col xl="3" md="3" sm="6">
                            <b-form-group>
                                <validation-provider #default="{ errors }" name="start-time" rules="required">
                                    <h5 class="font-weight-bold">{{ $t('Start Time') }}</h5>
                                    <flat-pickr v-model="startTime" class="form-control"
                                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', }" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!--End Time-->
                        <b-col xl="3" md="3" sm="6">
                            <b-form-group>
                                <validation-provider #default="{ errors }" name="end-time" rules="required">
                                    <h5 class="font-weight-bold">{{ $t('End Time') }}</h5>
                                    <flat-pickr v-model="endTime" class="form-control"
                                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', }" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!--Btn Send-->
                        <b-col xl="3" md="3" sm="12" class="align-middle">
                            <b-button :disabled="loadingHistory" class="mt-2 mb-2" variant="primary" type="submit"
                                @click.prevent="validationForm">
                                {{ $t('Send') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </validation-observer>
        <b-card>
            <b-row>
                <b-col xl="12" md="12" sm="12">
                    <div class="tableFixHead">
                        <b-table ref="refTelemetryTable" sticky-header="37rem" striped small hover
                            style="width: 100%; overflow-x: auto;" :items="rows" :fields="columns" primary-key="id"
                            show-empty :empty-text="emptyText">

                            <!-- Column: Date -->
                            <template #head(dateTime)>
                                {{ $t('Date') }}
                            </template>
                            <template #cell(dateTime)="data">
                                <div class="font-weight-bold d-block text-nowrap">
                                    {{ data.item.dateTime }}
                                </div>
                            </template>

                        </b-table>
                        <div class="text-center my-2">
                            <b-button v-if="showBtnSeeMore && !loading" @click="loadMoreTelemetry()" variant="primary">Ver
                                Mais</b-button>
                            <b-spinner v-if="loading" variant="primary" label="Text Centered" />
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BAvatar,
    BPagination,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BIcon,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BFormDatepicker,
    BTable,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
} from 'bootstrap-vue'

import { ref, onUnmounted } from '@vue/composition-api'

import router from '@/router'

import store from '@/store'

import queriesStoreModule from '../queriesStoreModule'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'

import { required } from '@validations'
import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'

import { VueGoodTable } from 'vue-good-table'


export default {
    components: {
        //Bootstrap
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,
        BAvatar,
        BPagination,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BSpinner,
        BFormDatepicker,
        BTable,
        //Validations
        ValidationProvider,
        ValidationObserver,
        //time picker
        flatPickr,
        vSelect,
        //Table
        VueGoodTable,


    },
    data() {
        return {
            //Rules
            required,

            //Form
            date: '',
            startTime: '',
            endTime: '',
            page: 1,
            loading: false,

            //Table
            pageLength: 25,
            columns: [],
            rows: [],
            showBtnSeeMore: true,
            emptyText: 'Nenhum registro correspondente encontrado.',


        }
    },
    mounted() {
        this.setInitialDate()
        this.composeReport(true)
    },
    methods: {
        setInitialDate() {
            const dataAtual = new Date();

            // Obter o ano, mês e dia
            const ano = dataAtual.getFullYear();
            const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
            const dia = String(dataAtual.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário

            // Formatar a data no formato YYYY-MM-DD
            const dataFormatada = `${ano}-${mes}-${dia}`;

            this.date = dataFormatada
            //this.date = '2023-08-08'
            this.startTime = '00:00'
            this.endTime = '23:59'
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.composeReport(true)
                }
            })
        },

        loadMoreTelemetry() {
            this.page++
            this.composeReport(false)
        },

        formatResponse(response) {
            if (response.headerNames.length > 0 && response.data && response.header) {
                this.responseToColumns(response)
                this.responseToRows(response)
            }
        },

        responseToColumns(response) {
            let headerNames = []
            headerNames.push({
                key: 'dateTime',
                label: "Data",
                sortable: false,
            })
            response.headerNames.map((name, index) =>
                headerNames.push({
                    key: response.header[index],
                    label: name,
                    sortable: false,
                    class: 'my-left-border'
                })
            );
            this.columns = headerNames
        },

        responseToRows(response) {
            response.data.map(data => {
                let jsonResult = {}
                response.header.map((key, index) => {
                    jsonResult[key] = data.data[index];
                    jsonResult.dateTime = this.formateDate(new Date(data.dateTime))
                });
                if (jsonResult) this.rows.push(jsonResult)
            })
        },

        formateDate(date) {
            const localDate = this.convertUTCDateToLocalDate(date)
            return localDate.toLocaleString().slice(0, -3)
        },

        convertUTCDateToLocalDate(date) {
            var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var offset = date.getTimezoneOffset() / 60;
            var hours = date.getHours();
            newDate.setHours(hours - offset);
            return newDate;
        },

        resetTable() {
            this.columns = []
            this.rows = []
            this.page = 1
            this.showBtnSeeMore = false
        },

        async composeReport(newRequest) {
            if (this.loading == true) return
            this.loading = true
            this.emptyText = 'Buscando Telemetria...'
            if (newRequest) {
                this.resetTable()
            }

            try {
                const response = await this.fetchTelemetryHistory(this.page)
                if (response.data) {
                    this.formatResponse(response.data)
                    this.showBtnSeeMore = true
                }

                if (response.data && response.data.data && response.data.data.length < this.pageLength) this.showBtnSeeMore = false
                this.loading = false
                this.emptyText = 'Nenhum registro correspondente encontrado.'
            }
            catch (e) {
                console.log("erro:", e)
                this.resetTable()
                this.loading = false
                this.showBtnSeeMore = false
                this.emptyText = 'Nenhum registro correspondente encontrado.'
                this.$toast.error('Ocorreu um erro inesperado');
            }


        },

        async fetchTelemetryHistory(page) {
            const response = await store.dispatch('app-queries/fetchTelemetryHistory', {
                assetId: this.assetId,
                date: this.date,
                startTime: this.startTime,
                endTime: this.endTime,
                page: page
            });
            return response
        }
    },
    setup() {
        const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'

        if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

        onUnmounted(() => {
            if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
        })

        const assetId = ref(router.currentRoute.params.id)

        return {
            QUERIES_APP_STORE_MODULE_NAME,
            assetId
        }
    }
}

</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';




.b-table .my-left-border {
    border-left: 1px solid #d1d0d3;
    font-size: smaller !important;
}
</style>